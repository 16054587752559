var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-card",
            { staticClass: "pb-4" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "white--text text-h5 mb-4",
                  attrs: { color: "secondary" },
                },
                [_vm._v(" New User ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-5 pa-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              { staticClass: "px-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex mt-4" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Company",
                                        dense: "",
                                        outlined: "",
                                        disabled: "",
                                        filled: "",
                                      },
                                      model: {
                                        value: _vm.company.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "name", $$v)
                                        },
                                        expression: "company.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "d-flex flex-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex mr-2" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.requiredRules,
                                          label: "First Name",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.users.user_fname,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.users,
                                              "user_fname",
                                              $$v
                                            )
                                          },
                                          expression: "users.user_fname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.requiredRules,
                                          label: "Last Name",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.users.user_lname,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.users,
                                              "user_lname",
                                              $$v
                                            )
                                          },
                                          expression: "users.user_lname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "d-flex flex-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex mr-2" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.requiredRules,
                                          label: "Email",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.users.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.users, "email", $$v)
                                          },
                                          expression: "users.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.requiredRules,
                                          label: "Mobile",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.users.mobile,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.users, "mobile", $$v)
                                          },
                                          expression: "users.mobile",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        rules: _vm.requiredRules,
                                        label: "password",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.users.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.users, "password", $$v)
                                        },
                                        expression: "users.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                this.$router.history.current.params.id != 33
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            dense: "",
                                            outlined: "",
                                            rules: _vm.requiredRules,
                                            items: _vm.AccountUserOptions,
                                            label: "User Account Type",
                                          },
                                          model: {
                                            value: _vm.users.user_account_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.users,
                                                "user_account_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "users.user_account_type",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.RoleOptions,
                                        rules: _vm.requiredRules,
                                        dense: "",
                                        outlined: "",
                                        label: "Masterclass Role",
                                      },
                                      model: {
                                        value: _vm.users.user_role,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.users, "user_role", $$v)
                                        },
                                        expression: "users.user_role",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.AAERoleOptions,
                                        "item-text": "text",
                                        "item-value": "value",
                                        dense: "",
                                        outlined: "",
                                        label: "AAE Role",
                                      },
                                      model: {
                                        value: _vm.users.aae_role,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.users, "aae_role", $$v)
                                        },
                                        expression: "users.aae_role",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.VisionRoleOptions,
                                        "item-text": "text",
                                        "item-value": "value",
                                        dense: "",
                                        outlined: "",
                                        label: "Vision Role",
                                      },
                                      model: {
                                        value: _vm.users.vision_role,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.users,
                                            "vision_role",
                                            $$v
                                          )
                                        },
                                        expression: "users.vision_role",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "d-flex flex-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex mr-2" },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          rules: _vm.requiredRules,
                                          items: _vm.MainCategoryOptions,
                                          clearable: "",
                                          dense: "",
                                          outlined: "",
                                          label: "Main Category",
                                        },
                                        on: { change: _vm.updateMainCategory },
                                        model: {
                                          value: _vm.users.main_category,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.users,
                                              "main_category",
                                              $$v
                                            )
                                          },
                                          expression: "users.main_category",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.users.main_category === "TaxPOD Client"
                                    ? _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.TaxPODClientOptions,
                                              dense: "",
                                              outlined: "",
                                              label: "Sub category",
                                            },
                                            model: {
                                              value: _vm.users.sub_category,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.users,
                                                  "sub_category",
                                                  $$v
                                                )
                                              },
                                              expression: "users.sub_category",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.users.main_category === "Internal Staff"
                                    ? _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.InternalStaffOptions,
                                              dense: "",
                                              outlined: "",
                                              label: "Branch",
                                            },
                                            model: {
                                              value: _vm.users.sub_category,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.users,
                                                  "sub_category",
                                                  $$v
                                                )
                                              },
                                              expression: "users.sub_category",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.users.main_category === null ||
                                  _vm.users.main_category === "SmartCFO Client"
                                    ? _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              disabled: "",
                                              items: _vm.TaxPODClientOptions,
                                              dense: "",
                                              outlined: "",
                                              label: "Sub category",
                                            },
                                            model: {
                                              value: _vm.users.sub_category,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.users,
                                                  "sub_category",
                                                  $$v
                                                )
                                              },
                                              expression: "users.sub_category",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { staticClass: "pb-0" }, [
                                      _vm.checkIfCanAccessOnboarding
                                        ? _c(
                                            "div",
                                            { staticClass: "px-2" },
                                            [
                                              _c("ComponentDateModal", {
                                                attrs: {
                                                  label:
                                                    "Welcome Guide Sent ( Optional )",
                                                  data: _vm.users
                                                    .welcome_guide_sent,
                                                },
                                                on: {
                                                  updateDateData: function (
                                                    data
                                                  ) {
                                                    _vm.users.welcome_guide_sent =
                                                      data
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { staticClass: "pt-0" }, [
                                      _vm.checkIfCanAccessOnboarding
                                        ? _c(
                                            "div",
                                            { staticClass: "px-2" },
                                            [
                                              _c("ComponentDateModal", {
                                                attrs: {
                                                  label:
                                                    "Welcome WA Sent ( Optional )",
                                                  data: _vm.users
                                                    .welcome_wa_sent,
                                                },
                                                on: {
                                                  updateDateData: function (
                                                    data
                                                  ) {
                                                    _vm.users.welcome_wa_sent =
                                                      data
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "d-flex flex-row" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 font-weight-bold m",
                                      },
                                      [_vm._v(" Status : ")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-switch", {
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm.users.isEnable
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "text-h7 white--text",
                                                      attrs: { color: "green" },
                                                    },
                                                    [_vm._v(" Active ")]
                                                  )
                                                : _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "text-h7 white--text",
                                                      attrs: { color: "red" },
                                                    },
                                                    [_vm._v(" Deactive ")]
                                                  ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.users.isEnable,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.users, "isEnable", $$v)
                                        },
                                        expression: "users.isEnable",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end pt-5 px-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function () {
                          _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.api.isLoading, color: "primary" },
                      on: { click: _vm.validateInput },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
              _c("AError", {
                staticClass: "mt-3 mx-4",
                attrs: { api: this.api },
              }),
              _vm.nameExisted
                ? _c("div", { staticClass: "d-flex justify-end px-4 pb-4" })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }